<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-row>
        <b-col class='d-flex align-items-center'>
          <BackButton></BackButton>
          <h5 class='ml-2'>Generic Importer Settings for client
            <b-link v-if='client' :to='`/onboarding/client/edit/${client.id}`'>{{ client.display_name }}</b-link>
          </h5>
        </b-col>
        <b-col class='d-flex justify-content-end'>
          <b-button class='ml-3' @click='submit' type='button' :disabled='saving' id='btn_submit' variant='primary'>
            <b-spinner label='Loading...' variant='light' small v-if='saving'></b-spinner>
            <span v-else>Update configuration</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class='mt-4'>
        <b-col>
          <b-card class='mb-4'>
            <template #header>
              <h6 class='mb-0'><b>{{ fileTypeDetails.name }}</b> file configuration for</h6>
            </template>

            <b-alert v-if="uniqueIdConfigs" show>
              The following columns will be used to generate the Unique ID: {{ uniqueIdConfigs.join(', ') }}
            </b-alert>
            <b-alert v-else variant="warning" show>
              No unique key configured for this client. The ingestion will not work if the config key is not configured.
            </b-alert>

            <FileConfiguration
              :fileTypeDetails="fileTypeDetails"
              :filepaths="filepaths"
              :readOnly="false"
              :eligibilityProviders="providers.filter(p => p.type === 'eligibility').map(p => {
                return {
                  'id': p.id,
                  'filepath': p.filepath
                }
              })"
              :uniqueIdConfigs="uniqueIdConfigs"
              :configuration="configuration"
              @form-validation="($ev) => formValidation($ev)"/>

              <ClientFilePreview
                :config="configuration"
                processorEngine="generic"/>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>

import BackButton from '@/components/BackButton.vue';
import FileConfiguration from '@/views/Onboarding/GenericImporter/FileConfiguration.vue';
import ClientFilePreview from '../../../components/SFTP/Client/DryRun/FilePreview.vue';

export default {
  components: { FileConfiguration, BackButton, ClientFilePreview },
  data() {
    return {
      saving: false,
      loading: true,
      client: null,
      uniqueIdConfigs: [],
      fileTypeDetails: {},
      providers: [],
      configuredFilepaths: [],
      filepaths: [],
      configuration: {
        delimiter: null,
        external_unique_id_column_number: null,
        provider_id: null,
        file_type_id: null,
        folder: null,
        mapping: [],
        normalizers: {
          relationship: [],
          gender: [],
          date: [],
        },
        genericEligibilityMapping: [],
        quarantine_config: [],
      },
      isFormValid: null,
    };
  },
  async beforeMount() {
    await this.loadPage();
  },
  watch: {
    'configuration.provider_id': {
      handler(val) {
        this.configuration.folder = this.filepaths.find(filepath => filepath.id === val)?.label;
      },
      immediate: true,
    },
  },
  methods: {
    async loadPage() {
      this.loading = true;
      const clients = await this.$store.dispatch('Core/fetchClients');
      this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
      await this.getProviders();
      await Promise.all([
        this.getClientConfigs(this.$route.params.clientId),
        this.getFileTypeDetails(this.$route.params.fileTypeId),
      ]);

      this.providers.forEach(p => {
        // if filepath is not already in use and matches the pattern for this file type
        if (!this.configuredFilepaths.includes(p.filepath) && p.filepath.match(this.fileTypeDetails.filepath)) {
          this.filepaths.push({
            label: p.filepath,
            id: p.id,
          });
        }
      });

      await this.getConfiguration();
      this.loading = false;
    },
    async getClientConfigs(clientId) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getClientImporters', { clientId });
      this.uniqueIdConfigs = res?.data?.unique_id_fields || [];
      this.configuredFilepaths = res?.data?.configurations
        ?.filter(c => c.file_type_id === Number(this.$route.params.fileTypeId))
        .map(c => c.filepath) || [];
    },
    async getFileTypeDetails(id) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getGenericImporterConfigs', id);
      this.fileTypeDetails = res?.data || {};
    },
    async getConfiguration() {
      const { data: details } = await this.$store.dispatch('Sftp/GenericImporter/getGenericImportClientConfiguration', {
        providerId: this.$route.params.providerId,
        importerId: this.$route.params.fileTypeId,
        onboardingClientId: this.$route.params.clientId,
      });

      this.configuration.delimiter = details.delimiter;
      this.configuration.external_unique_id_column_number = details.external_unique_id_column_number;
      this.configuration.mapping = details.mapping || null;
      this.configuration.normalizers.relationship = details?.normalizers?.relationship;
      this.configuration.normalizers.gender = details?.normalizers?.gender;
      this.configuration.normalizers.date = details?.normalizers?.date;
      this.configuration.genericEligibilityMapping = details?.genericEligibilityMapping || [];
      this.configuration.provider_id = Number(this.$route.params.providerId);
      this.configuration.file_type_id = Number(this.$route.params.fileTypeId);
      this.configuration.quarantine_config = {
        enabled: Boolean(details.quarantine_enabled),
        mapping: details.quarantine_config || [],
      };
      this.configuration.quarantine_ingestion = details.quarantine_ingestion;
      this.filepaths.push({
        label: this.providers.find(p => p.id === Number(this.$route.params.providerId))?.filepath,
        id: Number(this.$route.params.providerId),
      });
      this.configuration.folder = this.filepaths.find(filepath => filepath.id === this.configuration.provider_id)?.label;
    },
    async getProviders() {
      const res = await this.$store.dispatch('Sftp/Provider/getProvidersList');
      this.providers = res?.data || [];
    },
    formValidation(isValid) {
      this.isFormValid = isValid;
    },
    async submit() {
      if (!this.isFormValid) {
        this.$noty.error('Please fill all required fields');
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/GenericImporter/updateGenericImportClientConfiguration', {
          importerId: this.$route.params.fileTypeId,
          onboardingClientId: this.$route.params.clientId,
          data: {
            mapping: this.configuration.mapping.map(m => ({
              field_id: m.field_id,
              column_number: Number(m.column_number),
              uuid: m.iftsm_uuid,
            })),
            normalizers: {
              relationship: this.configuration.normalizers.relationship,
              date: this.configuration.normalizers.date,
              gender: this.configuration.normalizers.gender,
            },
            genericEligibilityMapping: this.configuration.genericEligibilityMapping,
            delimiter: String.fromCharCode(this.configuration.delimiter),
            external_unique_id_column_number: this.configuration.external_unique_id_column_number,
            file_type_id: this.$route.params.fileTypeId,
            provider_id: this.configuration.provider_id,
            quarantine_enabled: this.configuration.quarantine_config.enabled,
            quarantine_config: this.configuration.quarantine_config.mapping || [],
          },
        });

        this.$noty.success('Configuration updated with success');
        this.$router.push({
          name: 'ViewGenericFileTypeConfiguration',
          params: {
            clientId: Number(this.$route.params.clientId),
            fileTypeId: this.$route.params.fileTypeId,
            providerId: this.configuration.provider_id,
          },
        });
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
